<template>
	<v-app>
		<v-main>
            <v-container fluid fill-height class="container px-6">
                <v-layout align-center justify-center>
                    <v-flex xs="12" sm="12" md="12" lg="12" xl="12">
                        <div v-if="isMobile()">
                            <v-img src="@/assets/blink-logo.png"></v-img>
                            <v-form lazy-validation class="mb-8" ref="form" v-model="valid">
                                <v-card class="mx-auto rounded-lg elevation-0 bg-color" :ripple="false" max-width="344">
                                    <v-card-text class="px-0">
                                        <v-alert dense text type="error" v-text="errors.message" v-if="errors.message"></v-alert>
                                        <v-text-field clearable label="Username" :rules="rules.username" :error-messages="errors.username" v-model="object.username"></v-text-field>
                                        <v-text-field clearable type="password" label="Password" :rules="rules.password" :error-messages="errors.password" v-model="object.password" maxlength="20"></v-text-field>
                                    </v-card-text>
                                    <v-card-actions class="px-0 py-4 pt-6">
                                        <v-btn block large class="rounded-0 elevation-0" color="primary" :ripple="false" :loading="loading" @click.prevent.stop="login">Sign In</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </div>
                        <div v-if="!isMobile()">
                            <v-card class="mx-auto rounded-lg elevation-0 bg-color text-center">
                                <v-card-text class="text-h4">
                                    The blink app is used for mobile device only. <br>Please use mobile to login blink app. <br>Thank you!
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
			<access-error-dialog ref="errorDialog"></access-error-dialog>
		</v-main>
	</v-app>
</template>


<script>

import { parseUrlParams } from '@/utils/route'
import { addLocalStorage } from '@/utils/auth'
import AccessErrorDialog from '../../shared/AccessErrorDialog.vue'

export default {
	name: 'LoginForm',
	components: {
        accessErrorDialog: AccessErrorDialog,
	},
	data() {
		return {
			loading: false,
			valid:true,
			object: {
				username: "",
				password: ""
			},
			rules: {
				username: [
					(value) => !!value || 'Username is required',
					(value) => value.length >= 6 && value.length <= 30 || 'Username must be between 6 and 30 characters'
				],
				password: [
					(value) => {
						if(!value) {
							return 'Password is required'
						}

						if(value.length < 6 || value.length > 20){
							return 'Password must be between 6 and 20 characters'
						}

						return true
					}
				]
			},
			errors: {},
			params: {}
		}
	},
	computed: {
		form() {
			return this.$refs.form
		},
        errorDialog() {
            return this.$refs.errorDialog
        }
	},
    mounted: function() {
        this.checkErrorDialog()
    },
	methods: {
		login: function() {
			if(this.form.validate()){
				this.loading = true
				this.object.source = 'mobile'

				this.$store.dispatch('auth/login', this.object).then((response) => {
                    this.saveData(response.data.object)
					this.$router.push({ path: this.getRedirectUrl() })
					this.loading = false
				}).catch((error) => {
					this.updateErrorMessage(error.message)
					this.loading = false
				})
			}
		},
        checkErrorDialog: function() {
            this.params = parseUrlParams(window.location.href)
            const error = this.params.get('e')

            if(error){
                this.errorDialog.open(error)
            }
        },
		getRedirectUrl: function() {
            const redirect = this.params.get('redirect')
            if(redirect){
                return redirect
            }else {
                return '/today'
            }
		},
		saveData: function(item) {
            addLocalStorage('blink-data', 'user', item)
		},
		updateErrorMessage: function(message) {
			this.errors.message = message
		},
        isMobile: function(){
            return this.$vuetify.breakpoint.mobile
        }
	}
}

</script>


<style scoped>

.container {
	min-height: 100% !important;
	width: 100% !important;
	overflow: hidden !important;
	background-color: #FAFAFA !important;
}

.bg-color {
    background-color: #FAFAFA !important;
}


</style>